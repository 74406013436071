export class CookieConsentEvent {
  service: String;
  status: CookieConsentStatus;

  constructor(service: string, status: CookieConsentStatus) {
    this.service = service;
    this.status = status;
  }
}

export enum CookieConsentStatus {
  enabled = "enabled",
  disabled = "disabled"
}
