import { CookieConsentStatus, CookieConsentEvent } from "./event";
import * as Cookies from "js-cookie";
import { readFileSync } from "fs";
import CookieCategories from "./services";
import * as Handlebars from "handlebars";

class CookieConsent {
  onceListeners: {
    [key: string]: { [key: string]: ((event: CookieConsentEvent) => void)[] };
  } = {};
  onListeners: {
    [key: string]: { [key: string]: ((event: CookieConsentEvent) => void)[] };
  } = {};

  constructor() {
    const pendingActions: [{ method: string; params: any[] }] = (window as any)
      ._mcca;
    if (pendingActions) {
      pendingActions.forEach(({ method, params }) => {
        const instanceMethod = this[method] as Function;
        if (instanceMethod) instanceMethod.apply(this, params);
      });
    }
    if (this.shouldShowBanner) this.askForConsent();
    else {
      this.fullConsent = this.fullConsent;
      this.triggerEvent(
        "all",
        this.fullConsent
          ? CookieConsentStatus.enabled
          : CookieConsentStatus.disabled
      );
    }
    this.setupModalHandlers();
    if ((window as any).MCC_CHOICE_PAGE) {
      this.loadCSS();
      this.openModal();
      document.body.removeChild(
        document.getElementsByClassName("mcc-choice-container-loading")[0]
      );
    }
  }

  get shouldShowBanner() {
    return !Cookies.get("_medicinaeCookieConsent");
  }

  get fullConsent() {
    return Cookies.get("_medicinaeCookieConsent") === "v1:all";
  }

  set fullConsent(value: Boolean) {
    Cookies.set("_medicinaeCookieConsent", `v1:${value ? "all" : "none"}`, {
      expires: 730,
      domain: ".medicinaesolutions.com"
    });
    this.triggerEvent(
      "all",
      value ? CookieConsentStatus.enabled : CookieConsentStatus.disabled
    );
  }

  triggerEvent(name: string, status: CookieConsentStatus) {
    const event = new CookieConsentEvent(name, status);
    if (this.onceListeners.all && this.onceListeners.all[status]) {
      let next = this.onceListeners.all[status].pop();
      while (next) {
        next(event);
        next = this.onceListeners.all[status].pop();
      }
    }
    if (this.onListeners.all && this.onListeners.all[status]) {
      const listeners = this.onListeners.all[status];
      for (let i = 0; i < listeners.length; i++) listeners[i](event);
    }
  }

  loadCSS() {
    const css = readFileSync("./dist/cookieConsent.css", "utf8");
    const style = document.createElement("style");
    style.type = "text/css";
    style.appendChild(document.createTextNode(css));
    document.head.appendChild(style);
  }

  askForConsent() {
    if ((window as any).MCC_CHOICE_PAGE) return;
    this.loadCSS();
    const banner: HTMLDivElement = document.createElement("div");
    banner.classList.add("mcc-cleanslate");
    banner.classList.add("mcc-banner");
    banner.innerHTML = readFileSync("./dist/banner.html", "utf8");
    document.body.appendChild(banner);
    banner.getElementsByClassName("mcc-ok")[0].addEventListener("click", e => {
      e.preventDefault();
      this.fullConsent = true;
      document.body.removeChild(banner);
      return false;
    });
  }

  setupModalHandlers() {
    const links = document.getElementsByClassName("mcc-modal-link");
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("click", e => {
        e.preventDefault();
        this.openModal();
        return false;
      });
    }
  }

  openModal() {
    const banners = document.getElementsByClassName("mcc-banner");
    for (let i = 0; i < banners.length; i++)
      banners[i].parentElement.removeChild(banners[i]);

    const modal: HTMLDivElement = document.createElement("div");
    modal.classList.add("mcc-cleanslate");
    modal.classList.add("mcc-modal");
    modal.innerHTML = readFileSync("./dist/modal.html", "utf8");
    document.body.appendChild(modal);
    modal
      .getElementsByClassName("mcc-choices-yes")[0]
      .addEventListener("click", e => {
        e.preventDefault();
        this.fullConsent = true;
        if ((window as any).MCC_CHOICE_PAGE) {
          window.location.replace("https://medicinaesolutions.com/");
          return;
        }
        document.body.removeChild(modal);
        return false;
      });
    modal
      .getElementsByClassName("mcc-choices-no")[0]
      .addEventListener("click", e => {
        e.preventDefault();
        this.fullConsent = false;
        if ((window as any).MCC_CHOICE_PAGE) {
          window.location.replace("https://medicinaesolutions.com/");
          return;
        }
        document.body.removeChild(modal);
        return false;
      });
  }

  on(
    service: string,
    status: CookieConsentStatus,
    listener: (event: CookieConsentEvent) => void
  ) {
    if (!this.onListeners[service]) this.onListeners[service] = {};
    if (!this.onListeners[service][status])
      this.onListeners[service][status] = [];
    this.onListeners[service][status].push(listener);
  }

  once(
    service: string,
    status: CookieConsentStatus,
    listener: (event: CookieConsentEvent) => void
  ) {
    if (!this.onceListeners[service]) this.onceListeners[service] = {};
    if (!this.onceListeners[service][status])
      this.onceListeners[service][status] = [];
    this.onceListeners[service][status].push(listener);
  }
}

(window as any).CookieConsent = new CookieConsent();
